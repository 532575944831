import { Avatar, Tooltip } from 'tailwind-ui'

interface ProfileRowProps {
  email: string
  first: string
  last: string
  id: string
  fallbackText?: string
  link?: string
  src?: string
}

export const name = (firstOrLast: string): string => {
  if (!firstOrLast) {
    return ''
  }

  if (typeof firstOrLast !== 'string') {
    return ''
  }

  return firstOrLast.charAt(0).toUpperCase()
}

export const firstAndLast = (first: string, last: string, full: boolean) => {
  if (!full) {
    if (!first && !last) {
      return null
    }

    if (first && !last) {
      return `${name(first)} .`
    }
    if (last && !first) {
      return `. ${name(last)}`
    }
    return `${name(first)} ${name(last)}`
  }
  if (first || last) {
    return `${first || ''} ${last || ''}`
  }
  if (!first && !last) return null
}

export function ProfileRow({
  email,
  first,
  last,
  src,
  id,
  fallbackText,
}: ProfileRowProps) {
  return (
    <div className="flex" key={id}>
      <div className="mr-3 flex-shrink-0 self-center">
        <Avatar
          // bg={getColorFromString(email)}
          // color={invert(getColorFromString(email), true)}
          name={firstAndLast(first, last, false) ?? ''}
          size="sm"
          src={src}
        />
      </div>
      <div className="self-center">
        <h4 className="text-sm font-bold whitespace-break-spaces leading-4 cursor-default">
          {firstAndLast(first, last, true) || fallbackText || 'Someone'}
        </h4>
        <Tooltip className="mt-1" information={email}>
          <p className="truncate leading-3 max-w-[180px] text-xs font-medium cursor-default">
            {email}
          </p>
        </Tooltip>
      </div>
    </div>
  )
}
