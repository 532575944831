import { ClassValue, cx } from 'tailwind-ui'
export function Center({
  children,
  className,
}: {
  children: React.ReactElement
  className?: ClassValue
}) {
  return (
    <div className={cx('flex justify-center items-center h-full', className)}>
      {children}
    </div>
  )
}
