import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from 'tailwind-ui'

//@todo design
const badgeVariants = cva(
  'inline-flex items-center justify-center rounded-full border px-2 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-center cursor-default',
  {
    variants: {
      variant: {
        default:
          'border-green-500/10 bg-green-200 text-green-800 hover:bg-green-200/80 dark:border-green-500/20 dark:bg-green-900/90 dark:text-green-200 dark:hover:bg-green-900/70',
        secondary:
          'border-blue-500/10 bg-blue-200 text-blue-800 hover:bg-blue-200/80 dark:border-blue-500/20 dark:bg-blue-900/90 dark:text-blue-200 dark:hover:bg-blue-900/70',
        warn: 'border-yellow-500/10 bg-yellow-400 text-yellow-800 hover:bg-yellow-200/80 dark:border-yellow-500/20 dark:bg-yellow-900/90 dark:text-yellow-200 dark:hover:bg-yellow-900/70',
        destructive:
          'border-red-500/10 bg-red-300 text-red-800 hover:bg-red-200/80 dark:border-red-500/20 dark:bg-red-900/90 dark:text-red-200 dark:hover:bg-red-900/70',
        outblue:
          'border-blue-400/50 bg-blue-200/40 text-blue-800 hover:bg-blue-200/80 dark:border-blue-400/30 dark:bg-blue-900/90 dark:text-blue-200 dark:hover:bg-blue-900/70',
        outpink:
          'border-stampede-400/50 bg-stampede-200/40 text-stampede-800 hover:bg-stampede-200/80 dark:border-stampede-400/30 dark:bg-stampede-900/90 dark:text-stampede-200 dark:hover:bg-stampede-900/70',
        subtle:
          'border-gray-500/10 bg-gray-200 text-gray-700 hover:bg-gray-200/80 dark:border-gray-500/20 dark:bg-gray-800/90 dark:text-gray-200 dark:hover:bg-gray-800/70',
        outline:
          'border-gray-500/10 text-foreground dark:border-gray-500/20 dark:text-foreground',
      },
      //@todo sizes
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  action?: (() => void) | null
}

function Badge({
  className,
  variant,
  children,
  action = null,
  ...props
}: BadgeProps) {
  return (
    <div
      className={cn(
        badgeVariants({ variant }),
        {
          'pl-3 pr-1 py-1': Boolean(action),
        },
        className
      )}
      {...props}
    >
      {children}
      {action ? (
        <button
          type="button"
          className="group relative rounded-full ml-1  h-3.5 w-3.5 hover:bg-gray-500/20 items-center justify-center flex"
          onClick={action}
        >
          <span className="sr-only">Remove</span>
          <svg
            viewBox="0 0 14 14"
            className="h-3.5 w-3.5 stroke-gray-600/50 dark:stroke-gray-400 dark:group-hover:stroke-gray-200 group-hover:stroke-gray-600/75"
          >
            <path d="M4 4l6 6m0-6l-6 6" />
          </svg>
          <span className="absolute -inset-1" />
        </button>
      ) : null}
    </div>
  )
}

export { Badge, badgeVariants }
