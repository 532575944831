export interface PaginatedRequest {
  offset: number
  limit: number
}

export interface PaginationRepsonse<T> {
  body: T[]
  has_more: boolean
  total: number
  next_offset: number
  isLoading?: boolean
}

export const initPage: PaginationRepsonse<any> = {
  body: [],
  has_more: false,
  next_offset: 0,
  total: 0,
  isLoading: false,
}

export interface ServiceLinkType {
  url: string | null
  label: string
  active: boolean
}

export interface PagingLinksType {
  first: string | null //'http://service.stg.stampede.ai/3c6f05e5-5336-11ea-9472-06a4d6597160/marketing/email-addresses?page=1'
  last: string | null //'http://service.stg.stampede.ai/3c6f05e5-5336-11ea-9472-06a4d6597160/marketing/email-addresses?page=1'
  prev: string | null
  next: string | null
  current?: string | null
  count?: number
}

export interface ServicePaginatedResponseType<T> {
  data: T[]
  links: PagingLinksType
  meta: {
    current_page: number
    from: number
    last_page: number
    links: ServiceLinkType[]
    path: string //'http://service.stg.stampede.ai/3c6f05e5-5336-11ea-9472-06a4d6597160/marketing/email-addresses'
    per_page: number
    to: number
    total: number

    /**
     *
     */
    next_cursor: string | null
    prev_cursor: string | null
  }
  isLoading?: boolean
}

export const defaultServicePagination: ServicePaginatedResponseType<undefined> =
  {
    isLoading: false,
    data: [],
    links: {
      first: '',
      last: '',
      prev: '',
      next: '',
    },
    meta: {
      current_page: 1,
      from: 0,
      last_page: 0,
      links: [],
      path: '',
      per_page: 25,
      to: 0,
      total: 0,
      prev_cursor: null,
      next_cursor: null,
    },
  }

export interface MorphPaginatedResponseType<T> {
  data: T[]
  links: PagingLinksType
}

export const initMorphPageResponse: MorphPaginatedResponseType<undefined> = {
  data: [],
  links: { first: null, last: null, prev: null, next: null },
}
