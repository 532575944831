'use client'
import * as React from 'react'

import { ChevronsUpDown } from 'lucide-react'
import { Collapsible, CollapsibleContent } from '@radix-ui/react-collapsible'
import { cn } from '../helpers/classnames'
import { SButton } from '../button/s-button'
import { Badge } from '../badge'
import { CheckIcon, LockClosedIcon, XMarkIcon } from '@heroicons/react/16/solid'
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../tabs'
import Drawer from '../main-drawer'

export type Option = {
  value: string
  name: string
  description?: string
  imageUrl?: string
  tab_key?: string
}

type TabKey = {
  id: string
  name: string
}

interface GridMultiSelectProps {
  options: Option[]
  selected?: string[]
  onChange: (values: string[]) => void
  placeholder?: string
  className?: string
  title?: string
  brandKit?: boolean
  tab_keys?: TabKey[]
  disabled?: boolean
  classNames?: {
    selected?: string
    list_item?: string
  }
}
export function GridMultiSelect({
  options = [],
  selected = [],
  onChange,
  placeholder = 'Select items...',
  className,
  title = 'Select Options',
  brandKit = false,
  tab_keys = [],
  disabled = false,
  classNames,
}: GridMultiSelectProps) {
  console.log({ selected })
  const [open, setOpen] = React.useState(false)
  const [showSelected, setShowSelected] = React.useState(true)

  const selectedText =
    selected.length === 0
      ? placeholder
      : `${selected.length} item${selected.length === 1 ? '' : 's'} selected`

  const toggleOption = (value: string) => {
    const newSelected = selected.includes(value)
      ? selected.filter((v) => v !== value)
      : [...selected, value]
    onChange(newSelected)
  }

  const removeSelected = (value: string, e: React.MouseEvent) => {
    e.stopPropagation()
    onChange(selected.filter((v) => v !== value))
  }

  const renderOptions = (filteredOptions: Option[]) => (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4 h-full">
      {filteredOptions.map((option) => {
        const select = !!selected.find((selected) => selected === option.value)
        return (
          <SButton
            key={option.value}
            className={cn(
              'min-h-20 aspect-sqaure relative flex flex-col items-center justify-center rounded-lg',
              select && 'border-2 !border-blue-500 animate-none',
              classNames?.selected && select ? classNames?.selected : ''
            )}
            onClick={() => toggleOption(option.value)}
          >
            <div className="flex flex-col space-y-2 ">
              {option.imageUrl && (
                <img
                  src={option.imageUrl}
                  alt={option.name}
                  className="w-full h-32 object-cover rounded-md mb-2"
                />
              )}
              <div>
                <span className="font-medium text-center">{option.name}</span>
              </div>
              {option.description && (
                <div>
                  <span
                    className={cn(
                      'text-sm text-muted-foreground text-center mt-1'
                    )}
                  >
                    {option.description}
                  </span>
                </div>
              )}
            </div>
          </SButton>
        )
      })}
    </div>
  )

  return (
    <Collapsible
      open={showSelected && selected.length > 0}
      onOpenChange={setShowSelected}
      className="w-full"
    >
      <div className="relative">
        <SButton
          onClick={() => setOpen(true)}
          disabled={disabled}
          aria-expanded={open}
          className={cn(
            'flex w-full justify-between h-10 rounded-lg pl-2 pr-2  ',
            className,
            showSelected && selected.length > 0 && '!rounded-b-none'
          )}
        >
          <div className="w-full flex justify-between">
            <span className="">{selectedText}</span>
            <ChevronsUpDown className="h-4 w-4" />
          </div>
        </SButton>
        <Drawer open={open} setOpen={() => setOpen(false)} title={title}>
          <div className="w-full p-4 h-full relative flex flex-col justify-between">
            <div className="">
              {tab_keys.length > 0 ? (
                <Tabs defaultValue={tab_keys[0].id} className="">
                  <TabsList className="flex space-x-2 rounded-xl bg-gray-100  p-8 !justify-start !overflow-x-scroll">
                    {tab_keys.map((tab) => (
                      <TabsTrigger
                        key={tab.id}
                        value={tab.id}
                        className={cn(
                          'w-full rounded-lg py-2.5 text-sm font-medium leading-5 ',
                          'ring-white/60 focus:outline-none focus:none'
                        )}
                      >
                        {tab.name}
                      </TabsTrigger>
                    ))}
                  </TabsList>
                  {tab_keys.map((tab) => (
                    <TabsContent key={tab.id} value={tab.id} className="h-90">
                      {renderOptions(
                        options.filter((option) => option.tab_key === tab.id)
                      )}
                    </TabsContent>
                  ))}
                </Tabs>
              ) : (
                renderOptions(options)
              )}
            </div>
            <div className="sticky bottom-0  bg-neutral-200/10 dark:bg-neutral-900/90">
              <SButton
                onClick={() => setOpen(false)}
                className="w-full rounded-lg bg-neutral-100 dark:bg-neutral-800"
              >
                Continue ({selectedText})
              </SButton>
            </div>
          </div>
        </Drawer>

        <CollapsibleContent className="w-full border rounded-b-lg ">
          <div className="py-2">
            <div className="flex flex-wrap gap-2">
              {selected.map((value) => {
                const option = options.find((opt) => opt.value === value)
                return (
                  <Badge
                    key={value}
                    className={cn(
                      ' flex items-center gap-1 pr-2 pl-2 space-x-2 bg-black dark:bg-white dark:text-black dark:hover-none hover:bg-gray-500 text-white dark:hover:bg-gray-500 dark:hover:border-none',
                      classNames?.list_item ? classNames?.list_item : ''
                    )}
                  >
                    {option?.name}
                    {!disabled ? (
                      <span>
                        <XMarkIcon
                          className={cn(
                            ' h-4 w-4 cursor-pointer fill-white dark:fill-black'
                          )}
                          onClick={(e) => removeSelected(value, e)}
                        ></XMarkIcon>
                      </span>
                    ) : (
                      <LockClosedIcon
                        className={cn(' h-3 w-4 cursor-pointer fill-white')}
                      ></LockClosedIcon>
                    )}
                  </Badge>
                )
              })}
            </div>
          </div>
        </CollapsibleContent>
      </div>
    </Collapsible>
  )
}
