'use client'
import { cva, type VariantProps } from 'class-variance-authority'
import { UserIcon } from '@heroicons/react/20/solid'
import { useState, ReactNode } from 'react'
import { cn } from 'tailwind-ui'
import type { ClassValue } from 'tailwind-ui'

const avatarVariants = cva(`inline-block rounded-full`, {
  variants: {
    //default
    //outline
    size: {
      // default: 'h-10 w-10 ',
      xs: 'h-6 w-6',
      base: 'h-10 w-10 ',
      sm: 'h-8 w-8 ',
      lg: 'h-12 w-12 text-3xl',
      //@todo get xl size
    },
  },
  defaultVariants: {
    size: 'base',
  },
})

export interface AvatarProps
  extends Omit<React.ComponentProps<'img'>, 'className'>,
    VariantProps<typeof avatarVariants> {
  name?: string
  icon?: unknown
  textClassName?: ClassValue
  cssBg?: string
  color?: string
  className?: ClassValue
  iconClassName?: ClassValue
  rightElement?: ReactNode
}
const getInitials = (str: string) => {
  const words = str.split(/\s+/)
  const firstInitial = words[0]?.[0] ?? ''
  const lastInitial = words[words.length - 1]?.[0] ?? ''
  return (firstInitial + lastInitial).toUpperCase()
}

const fallbackBase = 'inline-flex items-center justify-center rounded-full'

/**
 * @property cssBg — This needs to be a css string not a tailwind class
 */
export function Avatar({
  className,
  size,
  src,
  cssBg = '#f6f9f9',
  color = 'text-white',
  rightElement,
  icon,
  textClassName,
  iconClassName,
  ...props
}: AvatarProps) {
  const [hasError, setHasError] = useState(false)
  return (
    <>
      {!hasError && src && (
        <img
          className={cn(avatarVariants({ size }), className)}
          src={src}
          onError={() => {
            setHasError(true)
          }}
          {...props}
        />
      )}
      {(hasError || !src) && (
        <span
          className={cn(
            avatarVariants({ size }),
            fallbackBase,
            className,
            'relative items-center'
          )}
          {...props}
        >
          {rightElement ? rightElement : null}

          {props.name ? (
            <span
              className={cn(
                'text-md font-bold leading-none text-white items-center',
                size === 'xs' && 'text-sm',
                textClassName
              )}
              style={{ color }}
              {...props}
            >
              {getInitials(props.name)}
            </span>
          ) : icon ? (
            <>{icon}</>
          ) : (
            <UserIcon
              className={cn(
                'w-7 h-7 bg-gray-600 rounded-full overflow-hidden text-gray-300',
                iconClassName
              )}
              // fill="currentColor"
            />
          )}
        </span>
      )}
    </>
  )
}
