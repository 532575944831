'use client'
import { useEffect, useRef, useState } from 'react'
import { cn } from '../helpers/classnames'

function Bleed({
  children,
  className,
}: {
  children: React.ReactElement
  className?: string
}) {
  const childRef = useRef<React.ElementRef<'div'> | null>(null)

  const [offsetHeight, setOffsetHeight] = useState(0)
  useEffect(() => {
    if (childRef.current) setOffsetHeight(childRef.current.offsetHeight)
  }, [childRef])

  return (
    <>
      <div ref={childRef} className={cn('absolute right-0 left-0', className)}>
        {children}
      </div>
      <div style={{ height: offsetHeight }} />
    </>
  )
}

export { Bleed }
