'use client'
import React from 'react'
import { inputVariants } from '../input'
import { cn } from 'tailwind-ui'
import { VariantProps } from 'class-variance-authority'
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid'

export interface NumberInputProps
  extends Omit<
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >,
      'size' | 'value' | 'onChange'
    >,
    VariantProps<typeof inputVariants> {
  error?: string | boolean
  isTouched?: boolean
  /**
   * @param leftAddOn -- padding should be added to input to avoid overlay
   */
  leftAddOn?: string
  rightAddOn?: string
  value?: number
  onChange: (value: number | '') => void
  min?: number
  max?: number
}

const GREEN_SUCCESS =
  'focus:ring-green-500 bg-green-50 ring-green-500 border-green-500 text-green-900 placeholder-green-700 focus:border-green-500'
const PRIMARY_SUCCESS =
  'focus:ring-stampede-500 border-stampede-500 focus:border-stampede-500'
const ERROR_CLASS = 'border-red-500'

function NumberInput({
  value,
  onChange,
  leftAddOn,
  rightAddOn,
  min,
  max,
  error,
  isTouched,
  brandKit,
  size,
  className,
  ...rest
}: NumberInputProps) {
  const isError = Boolean(error && isTouched)
  const isSuccess = Boolean(!error && isTouched)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value)
    if (isNaN(newValue)) {
      onChange('')
      return
    }
    onChange(newValue)
  }

  const increment = () => {
    const newValue = value ? value + 1 : 1
    onChange(newValue)
  }

  const decrement = () => {
    const newValue = value ? value - 1 : 0
    onChange(newValue)
  }

  return (
    <>
      <div className="w-full relative ">
        {leftAddOn && (
          <div
            className={cn(
              'pointer-events-none absolute inset-y-0 left-1 top-1 flex items-center justify-center w-[32px] h-[32px] bg-white text-gray-700',
              brandKit
                ? 'rounded-button bg-brand text-brand-invert'
                : 'rounded-md'
            )}
          >
            <span className="font-bold">{leftAddOn}</span>
          </div>
        )}
        {rightAddOn ? (
          <div
            className={cn(
              'absolute inset-y-0 right-1 top-1 flex items-center justify-center w-[32px] h-[32px] bg-white text-gray-700',
              brandKit
                ? 'rounded-button bg-brand text-brand-invert'
                : 'rounded-md'
            )}
          >
            <span className="font-bold">{rightAddOn}</span>
          </div>
        ) : null}
        <div
          className={cn(
            'absolute top-[2px] right-[2px] flex flex-col h-9 overflow-hidden rounded-r-md',
            brandKit && 'rounded-r-brand',
            size === 'sm' && 'h-8',
            size === 'lg' && 'h-[2.8rem]',
            size === 'xl' && 'h-12',
            !value ? 'invisible' : null
          )}
        >
          <button
            onClick={increment}
            type="button"
            className={cn(
              'border-l-[1.5px] border-gray-200 flex-1 hover:bg-gray-100 flex items-center px-2 justify-center outline-none',
              brandKit && 'rounded-tr-button'
            )}
          >
            <PlusIcon className="h-3 w-3 fill-gray-500" />
          </button>
          <button
            type="button"
            onClick={decrement}
            className={cn(
              'border-l-[1.5px] border-t-[1px] border-gray-200 flex-1 border-t-gray-200 hover:bg-gray-100 flex items-center px-2 justify-center outline-none',
              brandKit && 'rounded-br-button'
            )}
          >
            <MinusIcon className="h-3 w-3 fill-gray-500" />
          </button>
        </div>

        <input
          type="number"
          className={cn(
            inputVariants({ brandKit, size, className }),
            isError && ERROR_CLASS,
            isSuccess ? (brandKit ? GREEN_SUCCESS : PRIMARY_SUCCESS) : null,
            leftAddOn && 'pl-[38px]',
            className,
            'no-spinners focus-within:z-50',
            // Remove default increment/decrement buttons
            '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
          )}
          min={min}
          max={max}
          value={value}
          onChange={handleChange}
          {...rest}
        />
      </div>
      {isError && <p className="mt-0.5 text-sm text-red-600">{error}</p>}
    </>
  )
}

export { NumberInput }
