import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from 'tailwind-ui'

const containerVariants = cva(['px-4 lg:px-8', ''], {
  variants: {
    layout: {
      default: '',
      constrained: 'w-full  md:px-6',
      breakpoint_constrained: 'container md:px-6',
    },
    mobile: {
      default: '',
      full: 'px-0',
    },
  },
  defaultVariants: {
    layout: 'default',
    mobile: 'default',
  },
})

interface ContainerProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof containerVariants> {}

export function ContentContainer({
  children,
  layout,
  mobile,
  className,
}: ContainerProps) {
  return (
    <div className={cn(containerVariants({ layout, mobile, className }))}>
      {children}
    </div>
  )
}

ContentContainer.displayName = 'ContentContainer'
