'use client'
import { RadioGroup } from '@headlessui/react'
import { cn } from 'tailwind-ui'

function RadioCards({
  options,
  onChange,
  brandKit,
  value,
  leftAddOn,
  className
}: {
  options: (string | number)[]
  onChange: (v: string | number) => void
  value: (typeof options)[0]
  brandKit?: boolean
  leftAddOn?: string
  className?: string
}) {
  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      className="w-full flex justify-evenly gap-3"
    >
      <RadioGroup.Label className="sr-only">Radio Cards</RadioGroup.Label>

      {options?.map((option) => (
        <RadioGroup.Option
          key={option}
          value={option}
          className={({ active, checked }) =>
            cn(
              'cursor-pointer focus:outline-none border',
              //   option === value
              //     ? 'ring-2 ring-stampede-600 ring-offset-2'
              //     : '',
              option === value
                ? [
                    'bg-stampede-600 text-white hover:bg-stampede-500',
                    brandKit
                      ? 'bg-brand !text-brand-invert hover:bg-brand hover:opacity-90'
                      : '',
                  ]
                : [
                    'text-gray-900',
                    brandKit ? 'text-brand hover:text-brand-invert' : '',
                  ],
              'flex flex-1 items-center justify-center  py-2 text-lg font-bold uppercase',
              [
                brandKit
                  ? 'hover:bg-brand hover:opacity-70 rounded-button border-brand'
                  : 'hover:bg-stampede hover:opacity-70 rounded-lg',
              ],
             className
            )
          }
          disabled={false}
        >
          <RadioGroup.Label as="span" className={cn(brandKit ? '' : '')}>
            {leftAddOn}
            {option}
          </RadioGroup.Label>
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  )
}

export { RadioCards }
