'use client'

import * as React from 'react'

import { ChevronDown, ChevronRight } from 'lucide-react'
import { Checkbox } from '../forms/checkbox'
import { cn } from '../helpers/classnames'

interface ExpandableCheckboxProps {
  description: string
  isChecked: boolean
  label: string
  onChecked: (is_active: boolean) => void
  children?: React.ReactNode
  brandKit?: boolean
}

const ExpandableCheckbox = ({
  description,
  isChecked,
  label,
  onChecked,
  children,
  brandKit = false,
}: ExpandableCheckboxProps) => {
  const [expanded, setExpanded] = React.useState(isChecked)

  const toggleExpand = () => {
    const newExpandedState = !expanded
    setExpanded(newExpandedState)
    onChecked(newExpandedState)
  }

  React.useEffect(() => {
    setExpanded(isChecked)
  }, [isChecked])

  return (
    <>
      {React.Children.toArray(
        <div className={cn('w-full border rounded-lg overflow-hidden')}>
          <div
            className={cn(
              'flex items-start  space-x-2 p-2 cursor-pointer bg-neutral-100 dark:bg-neutral-900  hover:bg-neutral-200 dark:hover:bg-neutral-800 backdrop:transition-colors '
            )}
            onClick={toggleExpand}
          >
            <Checkbox
              checked={isChecked}
              onChange={(boolean) => {
                onChecked(!isChecked)
                setExpanded(!isChecked)
              }}
              onClick={(e) => e.stopPropagation()}
              label=""
              brandKit={brandKit}
            />
            <div className="flex justify-between w-full">
              <div className="flex flex-col">
                <div className="text-base font-semibold dark:text-white ">
                  {label}
                </div>
                <div className="text-sm text-gray-600 dark:text-white">
                  {description}
                </div>
              </div>
              <div>
                {expanded ? (
                  <ChevronDown className="h-5 w-5 text-gray-500" />
                ) : (
                  <ChevronRight className="h-5 w-5 text-gray-500" />
                )}
              </div>
            </div>
          </div>
          {expanded && children && <div className="p-4">{children}</div>}
        </div>
      )}
    </>
  )
}

export default ExpandableCheckbox
