'use client'

import { cn } from '../helpers/classnames'
import { RadioGroup, RadioGroupItem } from '../radio-group'
import * as React from 'react'

interface RadioCardsProps {
  options: {
    value: string
    label: string
  }[]
  defaultValue?: string
  onChange?: (value: string) => void
}

export default function RadioCardsGroup({
  options,
  defaultValue,
  onChange,
}: RadioCardsProps) {
  const [selectedValue, setSelectedValue] = React.useState(
    defaultValue || options[0]?.value
  )

  const handleChange = (value: string) => {
    setSelectedValue(value)
    onChange?.(value)
  }

  return (
    <RadioGroup
      value={selectedValue}
      onValueChange={handleChange}
      className="inline-flex w-full bg-neutral-200/90 dark:bg-black/80 rounded-lg p-1 border"
    >
      {options.map((option) => (
        <div key={option.value} className="flex-1">
          <RadioGroupItem
            value={option.value}
            id={option.value}
            className="peer sr-only"
          />
          <label
            htmlFor={option.value}
            className={cn(
              'flex items-center justify-center px-4 py-3 text-sm font-medium rounded-md cursor-pointer',
              'transition-all duration-200 ease-in-out',
              'bg-transparent text-zinc-400',
              'hover:bg-zinc-700 hover:text-zinc-100',
              selectedValue === option.value ? 'bg-zinc-700 text-zinc-100' : ''
            )}
          >
            {option.label}
          </label>
        </div>
      ))}
    </RadioGroup>
  )
}
