import { cva, type VariantProps } from 'class-variance-authority'
import {
  buttonVariants,
  ClassValue,
  cn,
  loadingVariants,
  Spinner,
} from 'tailwind-ui'
import { forwardRef } from 'react'
import { Icn, SvgIcon } from '../icon'

export const iconContainerVariants = cva(`flex items-center`, {
  variants: {
    size: {
      base: null,
      sm: null,
      lg: null,
    },
    isRight: {
      true: null,
      false: null,
    },
    isLeft: {
      true: null,
      false: null,
    },
  },
  defaultVariants: {
    size: 'base',
    isRight: false,
    isLeft: false,
  },
  compoundVariants: [
    {
      size: 'base',
      isRight: true,
      class: 'ml-1',
    },
    {
      size: 'sm',
      isRight: true,
      class: 'ml-1.5',
    },
    {
      size: 'lg',
      isRight: true,
      class: 'ml-2',
    },
    {
      size: 'base',
      isLeft: true,
      class: 'mr-1.5',
    },
    {
      size: 'sm',
      isLeft: true,
      class: 'mr-1',
    },
    {
      size: 'lg',
      isLeft: true,
      class: 'mr-2',
    },
  ],
})

export interface SButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  isLoading?: boolean
  leftIcon?: SvgIcon
  rightIcon?: SvgIcon
  classNames?: {
    leftIcon?: ClassValue
    rightIcon?: ClassValue
    rightIconContainer?: ClassValue
    isActiveClass?: ClassValue
    span?: string
  }
}

export const SButton = forwardRef<HTMLButtonElement, SButtonProps>(
  (
    {
      children,
      className,
      disabled: buttonDisabled,
      isLoading,
      variant = 'default',
      size = 'base',
      isDarkBg,
      isActive,
      brandKit,
      leftIcon: LeftIcon,
      rightIcon: RightIcon,
      classNames,
      ...props
    },
    ref
  ) => {
    const disabled = isLoading || buttonDisabled

    return (
      <button
        className={cn(
          '!hover:cursor-pointer touch-manipulation',
          buttonVariants({
            variant,
            isDarkBg,
            isActive,
            size,
            brandKit,
            className,
          }),
          isActive && (classNames?.isActiveClass ?? ''),
          isLoading &&
            'relative text-transparent transition-none hover:text-transparent  disabled:cursor-wait'
        )}
        disabled={disabled}
        ref={ref}
        type="button"
        {...props}
      >
        {isLoading ? (
          <div className="flex items-center justify-center w-full absolute left-0">
            <Spinner
              brandKit={brandKit}
              className={cn(
                loadingVariants({ variant: 'default', brandKit, size })
              )}
            />
          </div>
        ) : null}
        {!isLoading && LeftIcon ? (
          <div
            className={cn(
              iconContainerVariants({ size, isLeft: true }),
              !children && 'mr-0'
            )}
          >
            <Icn
              icon={LeftIcon}
              size={size}
              className={cn(size === 'lg' && 'h-6 w-6', classNames?.leftIcon)}
            />
          </div>
        ) : null}
        {children && (
          <span
            className={cn(
              {
                'opacity-0': isLoading,
                'opacity-100': !isLoading,
              },
              'flex justify-center w-full leading-none',
              classNames?.span
            )}
          >
            {children}
          </span>
        )}
        {!isLoading && RightIcon ? (
          <div
            className={cn(
              iconContainerVariants({ size, isRight: true }),
              !children && 'ml-0'
            )}
          >
            <Icn
              icon={RightIcon}
              size={size}
              className={cn(size === 'lg' && 'h-6 w-6', classNames?.rightIcon)}
            />
          </div>
        ) : null}
      </button>
    )
  }
)

SButton.displayName = 'SButton'
