import { cn } from '..'

interface PassedProps {
  value: number
  classNames?: string
  fillClassName?: string
}

export function Progress({ value, classNames, fillClassName }: PassedProps) {
  return (
    <div
      className={cn(
        'w-full h-0.5 bg-neutral-200 dark:bg-neutral-800 overflow-hidden',
        classNames
      )}
    >
      <div
        className={cn(
          'h-0.5 transition-all duration-500 ease-in-out',
          classNames,
          fillClassName
        )}
        style={{
          width: `${value}%`,
        }}
      />
    </div>
  )
}
