'use client'
import React, { forwardRef } from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { cva, type VariantProps } from 'class-variance-authority'
import { FadeInOut, cn } from 'tailwind-ui'
import { ErrorLabel } from '../labels/error'

export interface TextareaProps
  extends React.DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    VariantProps<typeof textAreaVariants> {
  error?: string
  isTouched?: boolean
  label?: string
  subClasses?: {
    label?: string
  }
  footer?: React.ReactNode
}

const textAreaVariants = cva(
  [
    'appearance-none px-3 py-3 form-textarea border-gray-200 w-full disabled:opacity-96 disabled:cursor-not-allowed border-black/10 dark:border-white/10 bg-neutral-50/90 dark:bg-neutral-950/90 focus:ring-stampede-500 focus:border-stampede-500 border-2',
  ],
  {
    variants: {
      brandKit: {
        true: 'rounded-brand focus:border-brand focus:ring-brand',
        false: 'rounded-md',
      },
      variant: {
        flushed:
          'border-0 bg-neutral-100 dark:bg-neutral-900 focus:ring-0 focus:bg-neutral-200 dark:focus:bg-neutral-800 ring-0',
      },
      errorLabel: {
        true: null,
        false: null,
      },
    },
    defaultVariants: {
      brandKit: false,
      errorLabel: true,
    },
  }
)

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      error,
      isTouched,
      className,
      subClasses,
      brandKit,
      label,
      errorLabel,
      variant,
      footer,
      ...rest
    },
    ref
  ) => {
    const isError = Boolean(error && isTouched)
    const isSuccess = Boolean(!error && isTouched)

    return (
      <div className="relative flex-1 w-full h-auto space-y-1">
        {label ? (
          <label className={cn(subClasses?.label)}>{label}</label>
        ) : null}
        <textarea
          className={cn(textAreaVariants({ brandKit, className, variant }), [
            isError
              ? 'focus:border-red-500 focus:ring-red-500 border-red-500'
              : null,
            isSuccess
              ? brandKit
                ? 'focus:ring-green-500 bg-green-50 border-green-500 text-green-900 focus:border-green-500'
                : 'focus:ring-stampede-500 border-stampede-500 focus:border-stampede-500'
              : null,
          ])}
          ref={ref}
          {...rest}
        />
        {footer ? <div className="mt-2">{footer}</div> : null}
        <FadeInOut
          className={cn(
            'h-5 w-5 rounded-full bg-stampede-500 flex items-center justify-center align-middle absolute right-2 top-[12px]',
            brandKit && 'bg-green-500'
          )}
          show={isSuccess}
        >
          <CheckIcon className="h-4 w-4 text-white" />
        </FadeInOut>
        <FadeInOut
          as="div"
          className="h-5 w-5 rounded-full bg-red-500 flex items-center justify-center align-middle absolute right-2 top-[12px]"
          show={isError}
        >
          <span className="h-4 w-4 text-white align-middle text-center leading-none	">
            !
          </span>
        </FadeInOut>

        {errorLabel && isError ? <ErrorLabel error={error} /> : null}
        {!errorLabel && isError ? (
          <p className="mt-0.5 text-sm text-red-600">{error}</p>
        ) : null}
      </div>
    )
  }
)

Textarea.displayName = 'Textarea'
