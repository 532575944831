'use client'
import { useState, useCallback, useEffect } from 'react'

interface PassedProps {
  timeout: number
  isEnd?: boolean
  onComplete?: () => void
  passed_percentage?: number
}

const interv = 50

export function ProgressBar({
  timeout,
  onComplete,
  isEnd = false,
  passed_percentage,
}: PassedProps) {
  const [percentage, setPercentage] = useState(isEnd ? 100 : 0)

  const countdown = useCallback(() => {
    if (isEnd) return

    const targetPercentage =
      passed_percentage !== undefined ? passed_percentage : 100
    const increment = targetPercentage / (timeout / interv)

    const intervalId = setInterval(() => {
      setPercentage((prev) => {
        const newPercentage = prev + increment
        if (newPercentage >= targetPercentage) {
          clearInterval(intervalId)
          if (targetPercentage === 100 && onComplete) onComplete()
          return targetPercentage
        }
        return newPercentage
      })
    }, interv)

    return () => clearInterval(intervalId)
  }, [onComplete, timeout, isEnd, passed_percentage])

  useEffect(() => {
    const cleanup = countdown()
    return () => {
      if (cleanup) cleanup()
    }
  }, [countdown])

  return (
    <div className="w-full border-brand border-2 rounded-full h-full bg-background-200">
      <div
        className="h-3 rounded-full bg-brand transition-all duration-500 ease-in-out"
        style={{
          width: `${percentage}%`,
        }}
      />
    </div>
  )
}
