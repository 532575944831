'use client'

import * as React from 'react'
import { checkboxVariants } from '../forms/checkbox'
import { cn, cx } from '../helpers/classnames'

interface ExpandableCheckboxProps {
  description: string
  checked: boolean
  label: string
  onChange: (is_active: boolean) => void
  children?: React.ReactNode
  brandKit?: boolean
  defaultChecked?: boolean
  size?: 'sm' | 'base' | 'lg' | 'xl'
  classNames?: {
    wrapper?: string
    checkbox?: string
  }
}

const CheckboxCard = ({
  description,
  checked,
  label,
  onChange,
  brandKit = false,
  defaultChecked,
  size = 'base',
  classNames,
}: ExpandableCheckboxProps) => {
  return (
    <label
      className={cx(
        'flex border hover:cursor-pointer relative border-black/10 dark:border-black/10 rounded-lg p-1.5 dark:ring-neutral-700 bg-neutral-100 dark:bg-neutral-900 hover:bg-neutral-200 dark:hover:bg-neutral-800 backdrop:transition-colors gap-4 items-center',
        classNames?.wrapper
      )}
      htmlFor={label}
    >
      <input
        id={label}
        type="checkbox"
        name={label}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        className={cn(
          checkboxVariants({ size, brandKit }),
          'rounded-md ml-1.5'
        )}
      />
      <div className="flex flex-col items-start justify-center gap-1">
        <span className="text-base font-semibold text-left">{label}</span>
        {description && (
          <span className="text-sm text-neutral-600 dark:text-neutral-400 text-left">
            {description}
          </span>
        )}
      </div>
    </label>
  )
}

export { CheckboxCard }
