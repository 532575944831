import { Base64 } from 'js-base64'

interface OptionWidths {
  width: number
  height: number
}

interface Options {
  mobile: OptionWidths
  desktop: OptionWidths
  kind: 'png' | 'jpg'
  gravity: 'ce' | 'sm'
  resize: 'fit' | 'fill'
}

interface ImageTypes {
  header: Options
  background: Options
  full: Options
  story: Options
  tiny: Options
  gallery: Options
  avatar: Options
  galleryList: Options
  logo: Options
  meta: Options
  portrate: Options
  square: Options
  wide: Options
  thumbnail: Options
  tile: Options
  original: Options
}

const dimensions = (width: number, height: number): OptionWidths => ({
  height,
  width,
})

const options: ImageTypes = {
  wide: {
    mobile: dimensions(1000, 500),
    desktop: dimensions(1000, 500),
    kind: 'jpg',
    gravity: 'ce',
    resize: 'fill',
  },
  portrate: {
    mobile: dimensions(1000, 500),
    desktop: dimensions(500, 1000),
    kind: 'jpg',
    gravity: 'ce',
    resize: 'fill',
  },
  square: {
    mobile: dimensions(500, 500),
    desktop: dimensions(500, 500),
    kind: 'jpg',
    gravity: 'ce',
    resize: 'fit',
  },
  header: {
    mobile: dimensions(430, 150),
    desktop: dimensions(430, 150),
    kind: 'png',
    gravity: 'ce',
    resize: 'fit',
  },
  meta: {
    mobile: dimensions(1200, 627),
    desktop: dimensions(1200, 627),
    kind: 'jpg',
    gravity: 'ce',
    resize: 'fill',
  },
  full: {
    mobile: dimensions(600, 600),
    desktop: dimensions(1200, 1200),
    kind: 'jpg',
    gravity: 'ce',
    resize: 'fill',
  },
  background: {
    mobile: dimensions(300, 300),
    desktop: dimensions(600, 600),
    kind: 'jpg',
    gravity: 'ce',
    resize: 'fill',
  },
  story: {
    mobile: dimensions(300, 600),
    desktop: dimensions(300, 600),
    kind: 'jpg',
    gravity: 'sm',
    resize: 'fill',
  },
  tiny: {
    mobile: dimensions(32, 32),
    desktop: dimensions(32, 32),
    kind: 'jpg',
    gravity: 'ce',
    resize: 'fill',
  },
  gallery: {
    mobile: dimensions(300, 300),
    desktop: dimensions(300, 300),
    kind: 'jpg',
    gravity: 'ce',
    resize: 'fit',
  },
  galleryList: {
    mobile: dimensions(100, 50),
    desktop: dimensions(100, 50),
    kind: 'jpg',
    gravity: 'ce',
    resize: 'fill',
  },
  avatar: {
    mobile: dimensions(300, 300),
    desktop: dimensions(300, 300),
    kind: 'jpg',
    gravity: 'ce',
    resize: 'fill',
  },
  logo: {
    mobile: dimensions(150, 150),
    desktop: dimensions(150, 150),
    kind: 'png',
    gravity: 'ce',
    resize: 'fit',
  },
  thumbnail: {
    mobile: dimensions(150, 150),
    desktop: dimensions(150, 150),
    kind: 'png',
    gravity: 'ce',
    resize: 'fit',
  },
  tile: {
    mobile: dimensions(150, 150),
    desktop: dimensions(150, 150),
    kind: 'png',
    gravity: 'ce',
    resize: 'fit',
  },
  original: {
    mobile: dimensions(1000, 0),
    desktop: dimensions(1000, 0),
    kind: 'jpg',
    gravity: 'ce',
    resize: 'fill',
  },
}

const baseUrl = 'https://image.stampede.ai'

//const gravity = 'ce'
const enlarge = 1

export const generateImage = (
  filepath = '',
  type: keyof ImageTypes
): string => {
  if (!filepath) return ''
  const path = Base64.encode(filepath)
  const mutiplier = 2
  const kind = 'desktop'

  const option = options[type]

  const url = `${baseUrl}/${option.resize}/${option[kind].width * mutiplier}/${
    option[kind].height * mutiplier
  }/${option.gravity}/${enlarge}/${path}.${option.kind}`
  return url
  //https://nearly.online/images/fill/1400/1000/sm/0/plain/http://s3-eu-west-1.amazonaws.com/nearly.online/static/media/branding/BBDBNFDEYRJE/fle_4f0e8bffbe63c6d6446dbe2285637f08.png@jpg
}
