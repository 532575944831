'use client'
import { cn } from '../helpers/classnames'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../dialog'
import React from 'react'
import Heading from '../typeography/heading'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { SIconButton } from '../button/s-icon-button'

function ShadModal({
  children,
  isOpen,
  title,
  onClose,
  canDismiss = true,
  size: propsSize = 'md',
  classNames = {
    body: '',
    container: '',
  },
  ...rest
}: {
  children: React.ReactNode
  isOpen: boolean
  onClose: (closed: boolean) => void
  title?: string
  size?: 'md' | 'lg' | 'xl' | 'full'
  canDismiss?: boolean
  classNames?: {
    body?: string
    container?: string
  }
} & Omit<React.ComponentProps<typeof Dialog>, 'open' | 'onOpenChange'>) {
  const sizeClasses = React.useMemo(() => {
    switch (propsSize) {
      case 'md':
        return 'md:max-w-md'
      case 'lg':
        return 'md:max-w-2xl'
      case 'xl':
        return 'md:max-w-4xl'
      case 'full':
        return 'md:max-w-full'
      default:
        return ''
    }
  }, [propsSize])

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open && canDismiss) {
          onClose(true)
        }
      }}
      {...rest}
    >
      <DialogContent
        className={cn(
          'w-full p-0 transform border-0 gap-0 overflow-visible text-left align-middle transition-all',
          sizeClasses,
          {
            'bg-neutral-100/90 dark:bg-neutral-900/90 shadow-xl backdrop-blur-xl':
              Boolean(title) && propsSize !== 'full',
            'md:rounded-t-2xl md:rounded-2xl border border-black/5 dark:border-white/5':
              propsSize !== 'full',
            'min-h-dvh flex flex-col': propsSize === 'full',
            'md:border-0': !title,
          },
          classNames.container
        )}
      >
        {/* Close button always top-right if canDismiss */}
        <div className="flex  justify-between">
          {/* Only show a header/title if provided */}
          {title && (
            <DialogHeader
              className={cn('px-4 py-4', {
                'rounded-t-2xl ': propsSize !== 'full',
              })}
            >
              <Heading className="capitalize">{title}</Heading>
            </DialogHeader>
          )}
          {canDismiss && (
            <DialogClose className="z-10 absolute top-2 right-2" asChild>
              <SIconButton variant="ghost_default" icon={XMarkIcon} />
            </DialogClose>
          )}
        </div>
        <div
          className={cn(
            'flex-1 flex flex-col w-full px-4 pb-4',
            classNames.body
          )}
        >
          {children}
          <div className="h-safe" />
        </div>
      </DialogContent>
    </Dialog>
  )
}

export { ShadModal }
