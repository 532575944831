'use client'
import React, { forwardRef } from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn, Skeleton } from 'tailwind-ui'
import { ErrorLabel } from '../labels/error'

export interface SelectProps
  extends Omit<
      React.DetailedHTMLProps<
        React.SelectHTMLAttributes<HTMLSelectElement>,
        HTMLSelectElement
      >,
      'size'
    >,
    VariantProps<typeof selectVariants> {
  error?: string
  isTouched?: boolean
  divClassName?: string
  isLoading?: boolean
}

const selectVariants = cva(
  [
    'form-select transition-colors duration-150 px-3 pr-8 border-black/10 dark:border-white/10 text-ellipsis border-2 w-full bg-neutral-50/90 dark:bg-neutral-950/90',
  ],
  {
    variants: {
      variant: {
        flushed:
          'border-0 bg-neutral-100 dark:bg-neutral-900 focus:ring-0 focus:bg-neutral-200 dark:focus:bg-neutral-800 ring-0',
      },
      size: {
        sm: 'h-9 text-sm',
        base: 'h-10',
        lg: 'h-11',
        xl: 'h-13 py-3',
      },
      brandKit: {
        true: 'rounded-button focus:border-brand focus:ring-brand',
        false: 'rounded-lg focus:border-stampede-500 focus:ring-stampede',
      },
    },
    defaultVariants: {
      brandKit: false,
      size: 'base',
    },
  }
)

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      error,
      isTouched,
      brandKit,
      className,
      size,
      variant,
      divClassName,
      isLoading,
      ...rest
    },
    ref
  ) => {
    const isError = error && isTouched
    const isSuccess = !error && isTouched

    // need to come back to Loading on this and use the select varients just not sure why not working
    return (
      <>
        {isLoading ? (
          <Skeleton className="h-12 w-full rounded-lg"></Skeleton>
        ) : (
          <div className={cn('relative flex-1', divClassName)}>
            <select
              className={cn(
                selectVariants({ brandKit, size, className, variant }),
                isError &&
                  'focus:border-red-500 focus:ring-red-500 border-red-500',
                isSuccess
                  ? brandKit
                    ? 'focus:ring-green-500 bg-green-50 border-2 border-green-500 text-green-900 focus:border-green-500'
                    : 'focus:ring-stampede-500 border-2 border-stampede-500 focus:border-stampede-500'
                  : null
              )}
              {...rest}
              ref={ref}
            />

            {isError ? <ErrorLabel error={error} /> : null}
          </div>
        )}
      </>
    )
  }
)

Select.displayName = 'Select'
