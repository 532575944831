import { BrandKitType } from '..'
import { BackendDateType, UserProfile } from '../api'
import { OauthProductUser } from '../auth'
import { SupportedCurrencyType } from '../currency'
export interface GiftActivationType {
  id: string
  prettyId: string
  giftCardSettings: GiftSettingsType
  transactionId: string
  value: {
    amount: number
    currency: string
    formatted: string
  }
  profileId: number
  createdAt: BackendDateType
  activatedAt: BackendDateType
  redeemedAt: BackendDateType
  redeemedBy: OauthProductUser
  status: 'unpaid' | 'active' | 'redeemed' | 'refunded'
  profile: {
    email: string
    first: string
    last: string
    id: number
  }
  refundedBy: OauthProductUser
  refundedAt: BackendDateType
}

export interface GiftFormType extends GiftFormStub {
  currency: string
  dataOptIn: boolean
  emailOptIn: boolean
  smsOptIn: boolean
}

export interface GiftFormStub {
  email: string
  first: string
  last: string
  amount: number
}

export interface GiftCardType {
  id: string
  profileId: string
}

export interface PaymentIntentResponse {
  client_secret: string
  account_id: string
}

export interface GiftSettingsType {
  image?: string
  id?: string
  title?: string
  description: string
  stripe_connect_id: number
  stripe_account_id?: string
  serial?: string
  alias?: string | null
  organization_id?: string
  colour: string
  branding_id?: string
  background_image: string
  pretty_id?: string
  modified_at?: string
  created_at?: string
  deleted_at?: string | null
  organization_name?: string
  quantities?: number[]
  sender_from_type: 'manual' | 'sender' | 'primary' | 'stampede'
  sender_from_id?: string | null
  email_description?: string
  branding_settings?: BrandKitType
  currency: 'GBP' | 'USD' | 'EUR'
}
export const giftStatus = ['unpaid', 'active', 'redeemed', 'refunded'] as const

export interface GiftCardInstanceType {
  id: string
  pretty_id: string
  gift_card_settings: GiftSettingsType
  transaction_id: string
  amount: number
  currency: 'GBP' | 'EUR' | 'USD'
  formatted_amount: string
  profile_id: number
  created_at: string
  activated_at: string
  redeemed_at: string
  status: 'unpaid' | 'active' | 'redeemed' | 'refunded'
  user_profile: UserProfile
  refunded_by: string
  refunded_by_user: OauthProductUser | null
  redeemed_by: string
  redeemed_by_user: OauthProductUser | null
  refunded_at: string
  organization_registration_id: string
}
