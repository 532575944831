'use client'

import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/16/solid'
import { cn } from '../helpers/classnames'
import debounce from 'lodash/debounce'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../command'
import { Popover, PopoverContent, PopoverTrigger } from '../popover'
import { SButton, SButtonProps } from '../button/s-button'
import { useEffect, useMemo, useState } from 'react'
import { Spinner } from '../spinner'

interface ComboboxProps {
  options: { value: string; label: string }[]
  optionsPlaceholder?: string
  onSearch: (query: string) => void
  onSelect: (value: string) => void
  queryAction?: (value: string) => void
  queryActionText?: string
  triggerVariant?: SButtonProps['variant']
  triggerPlaceholder?: string
  triggerClassName?: string
  commandPlaceholder?: string
  contentClassName?: string
  isLoading?: boolean
}

export function Combobox({
  options = [],
  optionsPlaceholder,
  onSearch,
  onSelect,
  queryAction,
  queryActionText,
  triggerVariant = null,
  triggerClassName,
  triggerPlaceholder = 'Select...',
  commandPlaceholder = 'Search...',
  contentClassName,
  isLoading,
}: ComboboxProps) {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState('')
  const [query, setQuery] = useState('')

  const debouncedSearch = useMemo(
    () =>
      debounce((value: string) => {
        onSearch(value)
      }, 400),
    [onSearch]
  )

  useEffect(() => {
    debouncedSearch(query)

    return () => {
      debouncedSearch.cancel()
    }
  }, [query, onSearch])

  useEffect(() => {
    onSelect(value)
  }, [value])

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <SButton
          variant={triggerVariant}
          classNames={{
            span: 'justify-start',
          }}
          role="combobox"
          aria-expanded={open}
          className={cn('justify-between w-full', triggerClassName)}
          rightIcon={ChevronUpDownIcon}
        >
          {value ? value : triggerPlaceholder}
        </SButton>
      </PopoverTrigger>
      <PopoverContent
        className={cn(
          'p-0 !min-w-[var(--radix-popover-trigger-width)] ',
          contentClassName
        )}
        arrowClassName="!hidden"
      >
        <Command className="bg-white dark:bg-black w-full rounded-xl  ">
          <CommandInput
            value={query}
            onValueChange={setQuery}
            placeholder={commandPlaceholder}
          />
          {isLoading ? (
            <div className="flex justify-center items-center h-full p-4">
              <Spinner />
            </div>
          ) : (
            <CommandList>
              <CommandEmpty>
                {optionsPlaceholder ?? 'No options found.'}
              </CommandEmpty>
              <CommandGroup>
                {queryAction && query ? (
                  <CommandItem
                    key="query-command-item"
                    value={query}
                    onSelect={(v) => {
                      queryAction(v)
                      setQuery('')
                    }}
                  >
                    {queryActionText} `{query}`
                  </CommandItem>
                ) : null}
                {options.map((opt) => (
                  <CommandItem
                    key={opt.value}
                    value={opt.value}
                    onSelect={(currentValue) => {
                      setValue(currentValue === value ? '' : currentValue)
                      setOpen(false)
                    }}
                  >
                    {opt.label}
                    <CheckIcon
                      className={cn(
                        'ml-auto',
                        value === opt.value ? 'opacity-100' : 'opacity-0'
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          )}
        </Command>
      </PopoverContent>
    </Popover>
  )
}
