'use client'
import {
  Dialog,
  DialogPanel,
  DialogBackdrop,
  type DialogProps,
  DialogTitle,
} from '@headlessui/react'
import { useMemo } from 'react'
import { XMarkIcon } from '@heroicons/react/16/solid'
import { SIconButton } from '../button/s-icon-button'
import { cn } from '../helpers/classnames'

function Modal({
  children,
  isOpen,
  title,
  onClose,
  canDismiss = true,
  size: propsSize = 'md',
  classNames = {
    body: '',
    container: '',
  },
  ...rest
}: {
  children: React.ReactNode
  isOpen: boolean
  title?: string
  size?: 'md' | 'lg' | 'xl' | 'full'
  canDismiss?: boolean
  classNames?: {
    body?: string
    container?: string
  }
} & DialogProps<any>) {
  const size = useMemo(() => {
    switch (propsSize) {
      case 'md':
        return 'md:max-w-md'
      case 'lg':
        return 'md:max-w-2xl'
      case 'xl':
        return 'md:max-w-4xl'
      case 'full':
        return 'md:max-w-full'
    }
  }, [propsSize])
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        console.log('onClose', 'modal-closing')
        if (canDismiss) {
          onClose(true)
        }
      }}
      className="fixed inset-0 z-50 overflow-y-auto"
      {...rest}
    >
      <DialogBackdrop
        as="div"
        transition
        className={cn(
          'fixed transform-gpu  inset-0 backdrop-blur-lg',
          'transition-all duration-300 ease-out',
          'opacity-100 bg-white/75 dark:bg-black/75',
          'max-lg:data-[closed]:translate-x-full lg:data-[closed]:opacity-0 ',
          {
            'backdrop-blur-xl bg-white/80 ': !title,
            'bg-white/90 dark:bg-black/90': propsSize === 'full',
          }
        )}
      />

      <div
        className={cn(
          'h-full overflow-y-auto ',
          'flex min-h-dvh h-full md:justify-center text-center',
          {
            'items-start md:py-4': propsSize !== 'full',
          },
          classNames.container
        )}
      >
        <DialogPanel
          transition
          className={cn(
            'duration-300 ease-out max-lg:data-[closed]:translate-x-full lg:data-[closed]:translate-y-10 lg:data-[closed]:transform-[scale(90%)] lg:data-[closed]:opacity-0',
            ' w-full transform overflow-visible text-left align-middle  transition-all',
            size,
            {
              'bg-white/90 dark:bg-black/90 shadow-xl ':
                Boolean(title) && propsSize !== 'full',
              'md:rounded-t-2xl md:rounded-2xl md:border-t md:border border-black/10 dark:border-white/10':
                propsSize !== 'full',
              'min-h-dvh flex flex-col': propsSize === 'full',
              'md:border-0': !title,
            }
          )}
        >
          {title ? (
            <div
              className={cn(' p-4 px-4 relative  ', {
                'rounded-t-2xl border-b bg-white dark:bg-black border-black/10 dark:border-white/10':
                  propsSize !== 'full',
              })}
            >
              {canDismiss ? (
                <div className={cn('absolute right-2 top-0 pt-2  z-10')}>
                  <SIconButton
                    onClick={() => {
                      onClose(true)
                    }}
                    icon={XMarkIcon}
                    variant="ghost_default"
                    type="button"
                    className="rounded-2xl"
                  >
                    <span className="sr-only">Close modal</span>
                  </SIconButton>
                </div>
              ) : null}

              <DialogTitle
                as="h3"
                className="text-lg font-medium leading-6 !text-gray-900 dark:!text-gray-200 capitalize"
              >
                {title}
              </DialogTitle>
            </div>
          ) : (
            <div
              className={cn(
                'flex items-end justify-end  absolute  right-2 z-10',
                {
                  '-top-4': propsSize !== 'full',
                  'top-1': propsSize === 'full',
                }
              )}
            >
              <SIconButton
                onClick={() => {
                  onClose(true)
                }}
                icon={XMarkIcon}
                variant="ghost_default"
                type="button"
                size="lg"
                className="rounded-2xl hover:text-gray-900"
              >
                <span className="sr-only">Close modal</span>
              </SIconButton>
            </div>
          )}
          <div
            className={cn(
              'p-4 flex-1 flex flex-col size-full',
              classNames.body
            )}
          >
            {children}
            <div className="h-safe" />
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  )
}

export { Modal }
